* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#modal-root {
  z-index: 999999;
  position: relative;
}

.modal--inner {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 999;
}

button.close--modal {
  position: absolute;
  right: 190px;
  cursor: pointer;
  top: auto;
  transform: translate(-50%, 50%);
  box-shadow: none;
  border: 0;
  background: none;
}

@media (max-width: 969px) {
  button.close--modal {
    right: 0px;
    top: auto;
    cursor: pointer;
    transform: translate(0%, 0%);
    box-shadow: none;
    border: 0;
    margin: auto;
  }
  .changed--modal-inner-image {
    padding: 20px;
  }
}

@media (max-width: 969px) {
  img.changed--modal-inner-image {
    padding-left: 10px;
    padding-right: 60px;
  }
  .modal-in--images img {
    padding-top: 0;
    object-fit: contain;
    padding-bottom: 0;
    max-width: 100%;
  }

  .modal-in--images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    height: 100%;
    align-items: flex-start;
    align-self: flex-start;
    max-width: 100%;
  }
}

@media (min-width: 969px) {
  .modal-in--images img {
    max-width: 669px;
  }
}

.demo-image-wrapper:hover .demo-btn {
  opacity: 1;
  transform: translate(-50%, 0);
}

/* switch */
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 40px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 35px;
  height: 35px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}
/* switch */

/* order products */

.order-products_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
}

.hint {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: cubic-bezier(0.3, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.3, 0, 1, 1);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: slideleft___SJPtH;
  animation-name: slideleft___SJPtH;
  -webkit-animation-iteration-count: 4;
  animation-iteration-count: 4;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.order-products_list.center {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.order-products_list--item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 20px;
  height: 65px;
}

@-webkit-keyframes slideleft___SJPtH {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

@keyframes slideleft___SJPtH {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

/* order products */

/* helpers */
.elipsis-3 {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 43px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrollable-area {
  overflow: scroll;
  max-height: 100px;
  min-height: 60px;
}

.scrollable-area img {
  width: 40px;
}

.full-p * {
  font-size: 14px !important;
}

.box {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
}
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}

@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.w100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.pad-left-10 {
  padding-left: 10px;
}

.scrollArea {
  max-height: 350px;
  overflow: auto;
}

.placeholder-small::placeholder {
  font-size: 9px;
}

[name="sliderTypeSearchKey"]::placeholder {
  font-size: 14px;
}

.justify-space-between span {
  width: auto !important;
  margin-bottom: 10px;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-direction-column {
  flex-direction: column;
}

.slider-image--small {
  max-width: 70px;
  margin-right: 10px;
  object-fit: contain;
}

.margin-0  {
  margin: 0 !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.m5-5 {
  margin-top: 5px;
}

.font-weight-medium {
  font-weight: 600;
}

/* scroll */

.scroll {
  scroll-behavior: smooth;
}

.scroll {
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  min-width: 100%;
  overflow: auto;
  margin-left: -10px;
}

.scroll--item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
}

/* .scroll::-webkit-scrollbar {
	display: none;
} */

.scroll.active {
  cursor: grab;
  cursor: -webkit-grab;
}

.scroll .card {
  width: 16rem;
  height: auto;
  flex: 0 0 auto;
  margin: 0 0.75rem;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  color: #252a32;
  background: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.scroll .card-image {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-top: 110%;
}

.scroll .card-image img.responsive {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.scroll .card-inner {
  width: 100%;
  height: auto;
  padding: 1rem;
}
/* scroll */

.errorText {
  font-weight: 400;
  font-size: 14px;
  color: rgb(252, 102, 135);
  line-height: 1.2;
  margin-top: 5px;
  margin-bottom: 10px;
}

.color--error  {
  color: #fc5c63;
}

.targetInputArea {
  position: absolute;
  right: 20px;
  top: 15px;
}

.targetButton {
  border: none !important;
  background: none;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  width: auto;
  height: auto;
  outline: none;
  cursor: pointer;
}

.targetButton:hover svg {
  background: black;
  border-radius: 50%;
}

.targetButton:active {
  border: none;
  outline: none;
}

.targetInputArea input {
  max-width: 80px;
  background: white;
}

.targetInputArea input::placeholder {
  font-size: 13px;
  font-weight: 400;
  color: black;
}

.targetInputAreaTooltip {
  background-color: #eeeeee;
  padding: 5px;
  padding-left: 10px;
  position: fixed;
  right: 0;
  top: 150px;
  width: auto;
}

.targetInputAreaTooltip p {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: black;
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
/* helpers */

/* slider list */

.tabItemsResults--scrollable {
  max-height: 450px;
  overflow: auto;
  height: 100%;
  padding: 20px;
  padding-left: 5px;
  margin-top: 0;
  margin: 0;
}

.tabItemsResults--scrollable[data-area-name="product"] {
  display: flex;
  flex-wrap: wrap;
}

.tabItemsResults--scrollable[data-area-name="product"] > div {
  max-width: 30%;
  min-height: 150px;
  margin-right: 15px;
}

.tabItemsResults--scrollable[data-area-name="product"] > div:nth-child(3n) {
  margin-right: 0;
}

.react-toast-notifications__container {
  z-index: 9999999 !important;
}
.react-toast-notifications__toast__content {
  font-family: Mulish, sans-serif;
}

/* slider list */

@media print {
  .noPrint {
    display: none;
  }
}
@import "slider.css";
@import "loader.css";

.rst__moveHandle {
  border: solid #23c589 1px;
}

span.rst__rowTitle {
  font-family: "Mulish";
}

.rst__rowContents {
  min-width: 330px;
}

.re-ordering-button,
.re-ordering-save-button {
  font-weight: 700;
}

/* datagrid */
.datagrid-template {
  width: 100%;
  height: 100%;
  min-height: 600px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: white;
  margin-top: 10px;
  font-family: Mulish, sans-serif;
}

.datagrid-template .rdg {
  border: none;
}

.default-grid {
  width: 100%;
  height: 100%;
}

.rdg-group-cell-content {
  display: flex;
  align-items: center;
  align-self: center;
}

.rdg-group-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.rdg-cell {
  border-left: none !important;
  border-right: none !important;
  border-block-end: none !important;
  border-inline-end: none !important;
  transition: all 0.3s ease;
}

.rdg-row .rdg-cell {
  border-bottom: 1px solid #cbcbcb69 !important;
}

.filter-cell {
  outline: none !important;
}

.rdg-cell[aria-selected="true"] {
  outline: none !important;
  box-shadow: inset 0px 0px 1px 1px #eaeaea;
  background: white;
}

.filter-cell > div:first-child {
  border-block-end: none !important;
}

.rdg-header-row .rdg-cell {
  outline: none;
  box-shadow: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.datagrid-template {
  font-family: "Poppins";
}

.rdg-row .rdg-cell {
  font-size: 11px;
  font-weight: 400;
}

.rdg-header-row {
  font-size: 12px;
  font-weight: 600;
}

.datagrid-filters {
  display: flex;
  width: 100%;
}

.datagrid-filter-item {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.datagrid-filter-item h2 {
  font-family: "Poppins";
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  padding-left: 10px;
}

.datagrid-filter-item .items {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  width: 100%;
}

.datagrid-filter-item .item span {
  user-select: none;
}
.datagrid-filter-item .item.export-button span {
  margin-left: 4px;
}
.datagrid-filter-item .item {
  min-width: 110px;
  background: #e5e2e236;
  border-radius: 8px;
  font-family: "Poppins";
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  height: 30px;
  box-shadow: 5px 5px 5px 1px #e5e2e2;
  transition: all 0.4s ease;
  cursor: pointer;
}

.datagrid-filter-item .item:last-child {
  margin: 0;
}

.datagrid-filter-item .item.active {
  font-weight: 600;
}

.datagrid-filter-item .item.active,
.datagrid-filter-item .item:hover {
  box-shadow: 5px 10px 5px 1px #aba8a885;
}

.rdg-header-sort-name {
  text-align: center;
}

.rdg-checkbox-input:checked+.rdg-checkbox {
  background-color: #23c58d;
}

/* datagrid */

@-webkit-keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes swing {
  15% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }
  65% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  80% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.swing:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.none-element {
  display: none;
}

.m-10 {
  margin: 10px !important;
}

.in-image-maxWidth-50 img {
  max-width: 50px;
}

.command-palette .command-palette-content {
  font-family: 'Mulish';
}


/* body Page */
.shadow-dropdown, .shadow-input {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
.shadow-dropdown {
  --tw-shadow: 0px 2px 16px rgba(0,0,0,.08);
  --tw-shadow-colored: 0px 2px 16px var(--tw-shadow-color);
}
/* body Page */

.text-white {
  color: 'white';
}