  .wrapper {
      /* position: absolute; */
      padding-top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      width: 100%;
      height: 100%;
      filter: url("#filter");
  }
  .ball {
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  .ball:before {
    background: #23c58d;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
  .ball:nth-child(1) {
    transform: translate(-66px);
    -webkit-animation: x-axis-lateral 2s infinite alternate ease-in-out;
            animation: x-axis-lateral 2s infinite alternate ease-in-out;
  }
  .ball:nth-child(1):before {
    -webkit-animation: y-axis-lateral 1s infinite 0.1s alternate ease-in-out;
            animation: y-axis-lateral 1s infinite 0.1s alternate ease-in-out;
  }
  .ball:nth-child(2) {
    -webkit-animation: x-axis 2s infinite alternate ease-in-out;
            animation: x-axis 2s infinite alternate ease-in-out;
  }
  .ball:nth-child(2):before {
    -webkit-animation: y-axis 1s infinite 0.5s alternate ease-in-out;
            animation: y-axis 1s infinite 0.5s alternate ease-in-out;
  }
  .ball:nth-child(3) {
    transform: translate(66px, 78px);
    -webkit-animation: x-axis-lateral 2s infinite alternate ease;
            animation: x-axis-lateral 2s infinite alternate ease;
  }
  .ball:nth-child(3):before {
    -webkit-animation: y-axis-lateral 1s infinite 0.4s alternate ease-in-out;
            animation: y-axis-lateral 1s infinite 0.4s alternate ease-in-out;
  }
  
  @-webkit-keyframes x-axis {
    0% {
      transform: translate(-96px);
    }
    100% {
      transform: translate(96px);
    }
  }
  @keyframes x-axis {
    0% {
      transform: translate(-96px);
    }
    100% {
      transform: translate(96px);
    }
  }
  @-webkit-keyframes y-axis {
    0% {
      transform: translateY(42px);
    }
    100% {
      transform: translateY(-66px) scale(0.8);
      background: #39dac1;
    }
  }
  @keyframes y-axis {
    0% {
      transform: translateY(42px);
    }
    100% {
      transform: translateY(-66px) scale(0.8);
      background: #39dac1;
    }
  }
  @-webkit-keyframes x-axis-lateral {
    0% {
      transform: translate(-36px);
    }
    100% {
      transform: translate(36px);
    }
  }
  @keyframes x-axis-lateral {
    0% {
      transform: translate(-36px);
    }
    100% {
      transform: translate(36px);
    }
  }
  @-webkit-keyframes y-axis-lateral {
    0% {
      transform: translateY(12px);
    }
    100% {
      transform: translateY(-60px);
      background: #39dac1;
    }
  }
  @keyframes y-axis-lateral {
    0% {
      transform: translateY(12px);
    }
    100% {
      transform: translateY(-60px);
      background: #39dac1;
    }
  }