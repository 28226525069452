.cellFormatter {
     /* needed on chrome */
  float: right;
  float: inline-end;
  display: table;
  block-size: 100%;

}
.cellFormatter span {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}
.rowDetail{
  padding: 24px;
}